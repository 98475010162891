/* eslint-disable indent */
import React from 'react'
import styled from '@emotion/styled'

import Text from '../common/Text'
import Space from '../common/Space'
import { theme } from '../../styles'
import Button from '../common/Button'

const BG = styled.div`
  display: flex;
  justify-self: center;
  position: absolute;
  top: 0;
  width: 250vw;
  margin-left: -50%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(
    to right,
    ${theme.color.purple900},
    ${theme.color.purple500} 60%,
    ${theme.color.green500} 80%
  );
  background: ${p =>
    p.backgroundColor === 'linear-gradient'
      ? `linear-gradient(to right, ${theme.color.purple900}, ${theme.color.purple500} 60%, ${theme.color.green500} 80%)`
      : theme.color[p.backgroundColor]};

  @media (max-width: 1050px) {
    background: ${p =>
      p.backgroundColor === 'linear-gradient'
        ? `linear-gradient(to right, ${theme.color.purple900}, ${theme.color.purple500}, ${theme.color.purple900})`
        : theme.color[p.backgroundColor]};
  }
`

const Wrapper = styled.div`
  display: flex;
  min-height: 72px;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${props => (props.execSummary ? '24px 44px 24px 24px' : '8px 16px')};

  @media (max-width: 800px) {
    padding: 12px;
    flex-direction: column;

    p {
      text-align: center;
    }
  }
  p {
    margin-bottom: 0px !important;
  }
`

const StyledText = styled(Text)`
  strong {
    font-weight: 700 !important;
  }
`

const StyledButton = styled(Button)`
  p {
    font-size: 1rem !important;
  }
`

const AnnouncementBanner = ({ execSummary }) => (
  <Wrapper execSummary={execSummary}>
    <StyledText bodyMedium color="purple900">
      Listen to the Webinar: <strong>Digital Accessibility Index Results</strong>
    </StyledText>
    <Space width={120} tabletWidth={60} mobileWidth={0} mobileHeight={8} />
    <StyledButton text="Listen Now" to="https://www.youtube.com/watch?v=KmnBckWJ2iE" buttonType="purple link CTA" />
    <BG className="bg" backgroundColor="orange500" />
  </Wrapper>
)

export default AnnouncementBanner
