import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import LayoutDAI from '../../components/LayoutDAI'
import AnnouncementBanner from '../../components/dai/AnnouncementBanner'
import Hero from '../../components/dai/Hero'
import Section from '../../components/dai/Section'
import Columns from '../../components/dai/Columns'
import Column from '../../components/dai/Column'
import { Heading1 } from '../../components/dai/Heading1'
import { Text } from '../../components/dai/Text'
import PageNavigation from '../../components/dai/PageNavigation'
import { BoldText } from '../../components/dai/BoldText'
import { List } from '../../components/dai/List'
import ScannerForm from '../../components/dai/ScannerForm'

import SEO from '../../components/SEO'
import Space from '../../components/common/Space'

export default function NextSteps() {
  return (
    <LayoutDAI
      freeAudit
      pagePath={{
        parent: 'next-steps',
        child: '',
        freeAuditHeading: 'Uncover even more accessibility barriers with an Expert Audit',
      }}
    >
      <SEO
        title="Next Steps | 2023 Digital Accessibility Index"
        pathname="/digital-accessibility-index/next-steps/"
        desc="Learn why a combination of automated scans and expert human audits can help you find and fix more accessibility barriers."
        bannerUrl="https://images.prismic.io/audioeye-web/ab059e01-f934-4a21-8440-79e8bcca03d9_next-steps.jpg?auto=compress,format"
        imageAlt="A car getting worked on in an auto shop with a technician who is wearing an AudioEye coverall."
        canonical={`https://${
          process.env.GATSBY_ACTIVE_ENV === 'production' ? 'www' : 'beta'
        }.audioeye.com/digital-accessibility-index/next-steps/`}
      />
      <AnnouncementBanner />
      <Hero
        image={
          <StaticImage
            src="../../images/dai/hero/next-steps.png"
            alt="A car getting worked on in an auto shop with a technician who is wearing an AudioEye coverall."
          />
        }
        noPaddingBottomMobile
      >
        <Text largeCapsInter color="orange900">
          Next Steps
        </Text>
        <Space height={24} />
        <Heading1 heading1 alt aria-label="Next Steps">
          Find out how your site compares.
        </Heading1>
        <Space height={32} />
        <Text>
          Today, digital is how the world works. But it doesn’t work for all.{' '}
          <BoldText>
            Digital experiences are broken for the 1.3 billion people globally who live with a disability.
          </BoldText>
        </Text>
        <Space height={32} />
        <Text>
          And as our automated scan and manual audits revealed, even companies that invest in digital accessibility
          often fall short. Largely because they only rely on one side of the equation — either automation-only
          solutions or manual, consultative approaches. Neither of which are sufficient on their own.
        </Text>
        <Space height={32} />
      </Hero>
      <Section paddingBottom="lg" constraint="lg" innerConstraint="md">
        <Text>
          That’s why AudioEye combines AI-driven automation with expert manual testing, including members of the
          disability community.
        </Text>
        <Space height={32} />
        <Text>
          Together we can make a huge difference in the lives of the people within the disability community and create
          digital experiences that actually work for everyone.
        </Text>
      </Section>
      <Section backgroundColor="orange300" paddingTop="lg" paddingBottom="lg" constraint="lg">
        <Columns>
          <Column size={9} tabletSize={24}>
            <Text heading3 className="asH4">
              How do you compare to top industry sites?
            </Text>
            <Space height={18} />
            <Text>Scan any URL to start identifying accessibility issues on your site.</Text>
          </Column>
          <Column size={15} tabletSize={24}>
            <ScannerForm />
          </Column>
        </Columns>
      </Section>
      <Section paddingTop="lg" paddingBottom="lg" constraint="lg" innerConstraint="md" backgroundColor="orange200">
        <Text largeCapsInter color="orange900">
          THANK YOU
        </Text>
        <Space height={32} />
        <Text>This wouldn’t have been possible without the expertise and support of:</Text>
        <Space height={32} />
        <Text>
          <BoldText>The AudioEye A11iance Community</BoldText>
        </Text>
        <Space height={32} />
        <List>
          <li>
            <Text>Ana Jacob</Text>
          </li>
          <li>
            <Text>Chris Preiman</Text>
          </li>
          <li>
            <Text>Christina Campbell</Text>
          </li>
          <li>
            <Text>Jennifer Piening</Text>
          </li>
          <li>
            <Text>Lia Stone</Text>
          </li>
          <li>
            <Text>Marche Roberson</Text>
          </li>
          <li>
            <Text>Maxwell Ivey</Text>
          </li>
          <li>
            <Text>TJ Olsen</Text>
          </li>
          <li>
            <Text>Wren Higginbottom</Text>
          </li>
        </List>
        <Space height={32} />
        <Text>
          <BoldText>AudioEye Team Members with IAAP CPACC & WAS Certifications</BoldText>
        </Text>
        <Space height={32} />
        <List>
          <li>
            <Text>Kaely Wang</Text>
          </li>
          <li>
            <Text>Sarah Kinneer</Text>
          </li>
          <li>
            <Text>Alisa Smith</Text>
          </li>
          <li>
            <Text>Chris Zomp</Text>
          </li>
        </List>
      </Section>
      <PageNavigation previousPageLink="/digital-accessibility-index/methodology/" previousPageText="Methodology" />
    </LayoutDAI>
  )
}
